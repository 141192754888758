import axios from '@axios'

function initialState() {
  return {
    title: {},
    link: 'library',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    title: state => state.title,
    link: state => state.link,
  },
  mutations: {
    UPDATE_TITLE(state, val) {
      state.title = val
    },
    UPDATE_LINK(state, val) {
      state.link = val
    },
  },
  actions: {
    saveFile(ctx, { data }) {
      const formData = new FormData()
      formData.append('title', data.title)
      formData.append('file', data.file)
      return new Promise((resolve, reject) => {
        axios
          .post('/library/upload', formData)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    listFile() {
      return new Promise((resolve, reject) => {
        axios
          .get('/library/list')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    editFile(ctx, { data }) {
      const formData = new FormData()
      formData.append('id', data.id)
      formData.append('title', data.title)
      if (data.file.length !== 0) {
        formData.append('file', data.file)
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/library/update', formData)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    removeFile(ctx, { data }) {
      const idArray = []
      data.forEach(value => {
        idArray.push(value.id)
      })
      return new Promise((resolve, reject) => {
        axios
          .post('/library/destroy', { library_ids: idArray })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    sendFile(ctx, { data }) {
      const idArray = []
      data.forEach(value => {
        idArray.push(value.id)
      })
      return new Promise((resolve, reject) => {
        axios
          .post('/member/content/send', { library_ids: idArray, member_id: this.getters['patient/patientId'] })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
