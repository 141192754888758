import axios from '@axios'

function initialState() {
  return {
    showProfile: '',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    showProfile: state => state.showProfile,
  },
  mutations: {
    UPDATE_SHOW_PROFILE(state, val) {
      state.showProfile = val
    },
  },
  actions: {
    listType() {
      return new Promise((resolve, reject) => {
        axios
          .get('/registration/type/enumerate')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listState() {
      return new Promise((resolve, reject) => {
        axios
          .get('/state/enumerate')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listGender() {
      return new Promise((resolve, reject) => {
        axios
          .post('/resource/genders/enumerate')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listWorker() {
      return new Promise((resolve, reject) => {
        axios
          .get('/worker/show')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    save(ctx, dataForm) {
      return new Promise((resolve, reject) => {
        axios
          .post('/worker/update', dataForm)
          .then(response => { resolve(response) })
          .catch(error => reject(error.response))
      })
    },
  },
}
