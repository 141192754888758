import axios from '@axios'

function initialState() {
  return {
    message: '',
    password: '',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {},
  mutations: {
    UPDATE_SHOW_MESSAGE(state, val) {
      state.message = val
    },
    UPDATE_PASSWORD(state, val) {
      state.password = val
    },
  },
  actions: {
    sendEmail(ctx, { email }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/worker/email/password/send', { email })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    savePassword(ctx, { token, password }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/worker/password/update', { token, password })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    validateToken(ctx, token) {
      return new Promise((resolve, reject) => {
        axios
          .post('/worker/password/token/validate', token)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
