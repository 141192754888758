import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import cuido from './cuido/cuido'
import register from './cuido/register'
import login from './cuido/login'
import password from './cuido/password'
import breadcrumb from './cuido/breadcrumb'
import patient from './cuido/patient'
import favorite from './cuido/favorite'
import formMedication from './cuido/form-medication'
import formOther from './cuido/form-other'
import profile from './cuido/profile'
import library from './cuido/library'
import stock from './cuido/stock'
import resume from './cuido/resume'
import bariatric from './cuido/bariatric'
import protocolModel from './cuido/protocol-model'
import survey from './cuido/survey'
import notificationConfig from './cuido/notification-config'
import notification from './cuido/notification'
import bariatricListFilter from './cuido/bariatric/filter-list'

const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    cuido,
    register,
    login,
    password,
    breadcrumb,
    patient,
    formMedication,
    formOther,
    favorite,
    profile,
    library,
    stock,
    resume,
    bariatric,
    protocolModel,
    survey,
    notificationConfig,
    notification,
    bariatricListFilter,
  },

  plugins: [
    createPersistedState({
      storage: {
        sessionStorage,
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  strict: process.env.DEV,
})
