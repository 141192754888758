import { bariatricPartners } from '../../utils/partners'

export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/password-email',
    name: 'password-email',
    component: () => import('@/views/pages/password/Password-email.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/password-new',
    name: 'password-new',
    component: () => import('@/views/pages/password/Password-new.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/resume',
    name: 'resume',
    component: () => import('@/views/pages/resume/Resume.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Resumo',
    },
  },
  {
    path: '/login-welcome',
    name: 'login-welcome',
    component: () => import('@/views/pages/authentication/Login-welcome.vue'),
    meta: {
      layout: 'full',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/register-email',
    name: 'register-email',
    component: () => import('@/views/pages/authentication/Register-email.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/register-code',
    name: 'register-code',
    component: () => import('@/views/pages/authentication/Register-code.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/register-name',
    name: 'register-name',
    component: () => import('@/views/pages/authentication/Register-name.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/register-board',
    name: 'register-board',
    component: () => import('@/views/pages/authentication/Register-board.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/register-password',
    name: 'register-password',
    component: () => import('@/views/pages/authentication/Register-password.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/register-finish',
    name: 'register-finish',
    component: () => import('@/views/pages/authentication/Register-finish.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/patient',
    name: 'patient',
    component: () => import('@/views/pages/patient/Patient-list.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Pacientes',
    },
  },
  {
    path: '/patient-edit',
    name: 'patient',
    component: () => import('@/views/pages/patient/Patient-edit.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dados do paciente',
      return: {
        url: '/patient',
      },
    },
  },
  {
    path: '/patient-medication',
    name: 'patient',
    component: () => import('@/views/pages/patient/reminder/Reminder-medication.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dados do paciente',
    },
  },
  {
    path: '/patient-time',
    name: 'patient',
    component: () => import('@/views/pages/patient/reminder/Reminder-time.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dados do paciente',
    },
  },
  {
    path: '/patient-frequency',
    name: 'patient',
    component: () => import('@/views/pages/patient/reminder/Reminder-frequency.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dados do paciente',
    },
  },
  {
    path: '/patient-duration',
    name: 'patient',
    component: () => import('@/views/pages/patient/reminder/Reminder-duration.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dados do paciente',
    },
  },
  {
    path: '/patient-finish',
    name: 'patient',
    component: () => import('@/views/pages/patient/reminder/Reminder-finish.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dados do paciente',
    },
  },
  {
    path: '/patient-other-title',
    name: 'patient',
    component: () => import('@/views/pages/patient/other-reminder/Other-reminder-title.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dados do paciente',
    },
  },
  {
    path: '/patient-other-time',
    name: 'patient',
    component: () => import('@/views/pages/patient/other-reminder/Other-reminder-time.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dados do paciente',
    },
  },
  {
    path: '/patient-other-frequency',
    name: 'patient',
    component: () => import('@/views/pages/patient/other-reminder/Other-reminder-frequency.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dados do paciente',
    },
  },
  {
    path: '/patient-other-finish',
    name: 'patient',
    component: () => import('@/views/pages/patient/other-reminder/Other-reminder-finish.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Dados do paciente',
    },
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: () => import('@/views/pages/favorite/Favorite-list.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Medicamentos Favoritos',
    },
  },
  {
    path: '/favorite-edit',
    name: 'favorite',
    component: () => import('@/views/pages/favorite/Favorite-edit.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Medicamentos Favoritos',
    },
  },
  {
    path: '/favorite-medication',
    name: 'favorite',
    component: () => import('@/views/pages/favorite/Favorite-medication.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Medicamentos Favoritos',
    },
  },
  {
    path: '/favorite-time',
    name: 'favorite',
    component: () => import('@/views/pages/favorite/Favorite-time.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Medicamentos Favoritos',
    },
  },
  {
    path: '/favorite-frequency',
    name: 'favorite',
    component: () => import('@/views/pages/favorite/Favorite-frequency.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Medicamentos Favoritos',
    },
  },
  {
    path: '/favorite-duration',
    name: 'favorite',
    component: () => import('@/views/pages/favorite/Favorite-duration.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Medicamentos Favoritos',
    },
  },
  {
    path: '/favorite-finish',
    name: 'favorite',
    component: () => import('@/views/pages/favorite/Favorite-finish.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Medicamentos Favoritos',
    },
  },
  {
    path: '/library',
    name: 'library',
    component: () => import('@/views/pages/library/Library.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Biblioteca',
    },
  },
  {
    path: '/library-add',
    name: 'library',
    component: () => import('@/views/pages/library/Library-add.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Biblioteca',
      return: {
        url: '/library',
      },
    },
  },
  {
    path: '/library-edit',
    name: 'library',
    component: () => import('@/views/pages/library/Library-edit.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Biblioteca',
      return: {
        url: '/library',
      },
    },
  },
  {
    path: '/stock',
    name: 'stock',
    component: () => import('@/views/pages/stock/Stock.vue'),
    meta: {
      resource: 'ACL_STOCK',
      action: 'read',
      pageTitle: 'Liberar itens',
    },
  },
  {
    path: '/stock-medication',
    name: 'stock',
    component: () => import('@/views/pages/stock/Stock-medication.vue'),
    meta: {
      resource: 'ACL_STOCK',
      action: 'read',
      pageTitle: 'Liberar itens',
      return: {
        url: '/stock',
      },
    },
  },
  {
    path: '/stock-send',
    name: 'stock',
    component: () => import('@/views/pages/stock/Stock-send.vue'),
    meta: {
      resource: 'ACL_STOCK',
      action: 'read',
      pageTitle: 'Liberar item',
      return: {
        url: '/stock',
      },
    },
  },
  {
    path: '/stock-history',
    name: 'stock',
    component: () => import('@/views/pages/stock/Stock-history.vue'),
    meta: {
      resource: 'ACL_STOCK',
      action: 'read',
      pageTitle: 'Histórico de liberações',
      return: {
        url: '/stock',
      },
    },
  },
  {
    path: '/bariatric',
    name: 'bariatric',
    component: () => import('@/views/pages/bariatric/Bariatric-tab.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Bariátrica',
      return: {
        url: '/bariatric',
      },
      partners: bariatricPartners,
    },
  },
  {
    path: '/protocol-model-create',
    name: 'protocol-model-create',
    component: () => import('@/views/pages/bariatric/protocol-model/ProtocolModel-add.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Cadastrar um protocolo modelo',
      return: {
        url: '/bariatric',
      },
      partners: bariatricPartners,
    },
  },
  {
    path: '/protocol-model-edit',
    name: 'protocol-model-edit',
    component: () => import('@/views/pages/bariatric/protocol-model/ProtocolModel-edit.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Editar protocolo modelo',
      return: {
        url: '/bariatric',
      },
      partners: bariatricPartners,
    },
  },
  {
    path: '/bariatric-register',
    name: 'bariatric',
    component: () => import('@/views/pages/bariatric/Bariatric-register.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Cadastrar Jornada',
      return: {
        url: '/bariatric',
      },
      partners: bariatricPartners,
    },
  },
  {
    path: '/bariatric-detail',
    name: 'bariatric',
    component: () => import('@/views/pages/bariatric/Bariatric-detail.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Detalhe do paciente',
      return: {
        url: '/bariatric',
      },
      partners: bariatricPartners,
    },
  },
  {
    path: '/card-detail/:id',
    name: 'card-detail',
    component: () => import('@/views/pages/bariatric/card/Bariatric-card-detail.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Editar dados da solicitação da carteirinha',
      return: {
        url: '/bariatric?tab=card',
      },
      partners: bariatricPartners,
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
