import axios from '@axios'

function initialState() {
  return {
    action: '',
    idSelect: null,
    protocolModelName: '',
    protocolModelSpecialist: {},
    protocolModelSpecialistIds: [],
    protocolModelExams: {},
    protocolModelExamsIds: [],
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    action: state => state.action,
    idSelect: state => state.idSelect,
    protocolModelName: state => state.protocolModelName,
    protocolModelSpecialist: state => state.protocolModelSpecialist,
    protocolModelSpecialistIds: state => state.protocolModelSpecialistIds,
    protocolModelExams: state => state.protocolModelExams,
    protocolModelExamsIds: state => state.protocolModelExamsIds,
    selectedSpecialistIds: state => state.protocolModelSpecialist.selectedsHealthSpecialist,
    selectedExamIds: state => state.protocolModelExams.selectedExam,
    optionSpecialist: state => state.protocolModelSpecialist.optionsHealthSpecialists,
    optionExam: state => state.protocolModelExams.optionsExams,
  },
  mutations: {
    UPDATE_ACTION(state, val) {
      state.action = val
    },
    UPDATE_ID_SELECT(state, val) {
      state.idSelect = val
    },
    UPDATE_PROTOCOL_MODEL_NAME(state, val) {
      state.protocolModelName = val
    },
    UPDATE_PROTOCOL_MODEL_SPECIALIST(state, val) {
      state.protocolModelSpecialist = val
    },
    UPDATE_PROTOCOL_MODEL_SPECIALIST_IDS(state, val) {
      state.protocolModelSpecialistIds = val
    },
    UPDATE_PROTOCOL_MODEL_EXAMS(state, val) {
      state.protocolModelExams = val
    },
    UPDATE_PROTOCOL_MODEL_EXAMS_IDS(state, val) {
      state.protocolModelExamsIds = val
    },
    UPDATE_OPTION_SPECIALIST(state, val) {
      state.protocolModelSpecialist.optionsHealthSpecialists = val
    },
    UPDATE_OPTION_SPECIALIST_PUSH(state, val) {
      state.protocolModelSpecialist.optionsHealthSpecialists.push(val)
    },
    UPDATE_SELECT_SPECIALIST_REMOVE(state, val) {
      const index = state.protocolModelSpecialist.selectedsHealthSpecialist.indexOf(val)
      state.protocolModelSpecialist.selectedsHealthSpecialist.splice(index, 1)
    },
    UPDATE_OPTION_EXAM_PUSH(state, val) {
      state.protocolModelExams.optionsExams.push(val)
    },
    UPDATE_SELECT_EXAM_REMOVE(state, val) {
      const index = state.protocolModelExams.selectedExam.indexOf(val)
      state.protocolModelExams.selectedExam.splice(index, 1)
    },
    RESET(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
  },
  actions: {
    listProtocols() {
      return new Promise((resolve, reject) => {
        axios
          .post('bariatric/protocol/model/list')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    fetchProtocolModels(ctx, payload) {
      const data = {
        limit: payload?.perPage,
        page: payload?.page,
        sort_by: payload?.sortBy.toUpperCase(),
        sort_direction: payload?.sortDirection.toUpperCase(),
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/protocol/model/list', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listHealthSpecialists(ctx, payload) {
      const filters = {
        filters: {
          name: payload?.name,
          main: payload?.main,
        },
        limit: 100,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/health/specialist/search', filters)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listExams(ctx, payload) {
      const filters = {
        filters: {
          name: payload?.name,
          main: payload?.main,
        },
        limit: 100,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/exam/search', filters)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    create(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post('bariatric/protocol/model/create', { bariatric_protocol_model_info: data })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    show(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('bariatric/protocol/model/show', { bariatric_protocol_model_id: payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    edit(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('bariatric/protocol/model/update', { bariatric_protocol_model_id: this.getters['protocolModel/idSelect'], bariatric_protocol_model_info: payload.data })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    remove(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post('bariatric/protocol/model/destroy', { bariatric_protocol_model_ids: data })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addHealthSpecialist(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post('health/specialist/add', { health_specialist_info: data })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addExam(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post('exam/add', { exam_info: data })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
