import axios from '@axios'
import moment from 'moment'

function initialState() {
  return {
    labelText: [],
    dataValue: [],
    dateStart: '',
    dateEnd: '',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    label: state => state.labelText,
    data: state => state.dataValue,
    dateStart: state => state.dateStart,
    dateEnd: state => state.dateEnd,
  },
  mutations: {
    UPDATE_DATA_LABEL(state, val) {
      state.labelText = val
    },
    UPDATE_DATA_AGE(state, val) {
      state.dataValue = val
    },
    UPDATE_DATE_START(state, val) {
      state.dateStart = val
    },
    UPDATE_DATE_END(state, val) {
      state.dateEnd = val
    },
    RESET(state) {
      // acquire initial state
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
  },
  actions: {
    countMembers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/members/count')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listCare() {
      const filter = {
        language: 'pt_BR',
        start_date: moment(this.getters['resume/dateStart'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        end_date: moment(this.getters['resume/dateEnd'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/care/trend/list', { filters: filter })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listMedication() {
      const filter = {
        start_date: moment(this.getters['resume/dateStart'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        end_date: moment(this.getters['resume/dateEnd'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/medication/trend/list', { filters: filter })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listAge() {
      const filter = {
        start_date: moment(this.getters['resume/dateStart'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        end_date: moment(this.getters['resume/dateEnd'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/age/list', { filters: filter })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listGender() {
      const filter = {
        genders: [
          'M', 'F', 'OTHERS',
        ],
        start_date: moment(this.getters['resume/dateStart'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        end_date: moment(this.getters['resume/dateEnd'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/members/gender/report/list', { filters: filter })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    averageAdherence() {
      return new Promise((resolve, reject) => {
        axios
          .get('member/adherence/average')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    downloadCsv() {
      const filter = {
        start_date: moment(this.getters['resume/dateStart'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        end_date: moment(this.getters['resume/dateEnd'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        language: 'pt_BR',
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/worker/resume/download', { filters: filter })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
