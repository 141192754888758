import axios from '@axios'

function initialState() {
  return {
    email: '',
    code: '',
    name: '',
    registrationType: '',
    registrationtState: '',
    registrationCode: '',
    registrationAgreement: '',
    password: '',
    ip: '',
    browser: '',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    data: state => {
      const data = {
        worker_info: {
          email: state.email,
          password: state.password,
          name: state.name,
          agreement_id: state.registrationAgreement,
        },
        registration_info: [{
          code: state.registrationCode,
          type: state.registrationType,
          state: state.registrationtState.code,
        }],
        client_info: {
          ip_address: state.ip,
          user_agent: state.browser,
        },
      }
      return data
    },
  },
  mutations: {
    UPDATE_EMAIL(state, val) {
      state.email = val
    },
    UPDATE_CODE(state, val) {
      state.code = val
    },
    UPDATE_NAME(state, val) {
      state.name = val
    },
    UPDATE_TYPE(state, val) {
      state.registrationType = val
    },
    UPDATE_STATE(state, val) {
      state.registrationtState = val
    },
    UPDATE_REGISTRATION_CODE(state, val) {
      state.registrationCode = val
    },
    UPDATE_AGREEMENT(state, val) {
      state.registrationAgreement = val
    },
    UPDATE_PASSWORD(state, val) {
      state.password = val
    },
    UPDATE_IP(state, val) {
      state.ip = val
    },
    UPDATE_BROWSER(state, val) {
      state.browser = val
    },
    RESET(state) {
      // acquire initial state
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
  },
  actions: {
    sendEmail(ctx, { email }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/authcode/create', { email })
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    validateCode(ctx, { email, code }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/authcode/validate', { email, code })
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    registrationInfoValidate(ctx, { registrationInfo }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/worker/registration/info/validate', { registration_info: [registrationInfo] })
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    saveWorker(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/worker/create', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
  },
}
