import axios from '@axios'

function initialState() {
  return {
    communications: false,
    cares: false,
    isDefault: true,
    cardIsNewItemTab: false,
    indicatorsIsNewItemTab: false,
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    communication: state => state.communications,
    cares: state => state.cares,
    isDefault: state => state.isDefault,
    cardIsNewItemTab: state => state.cardIsNewItemTab,
    indicatorsIsNewItemTab: state => state.indicatorsIsNewItemTab,

  },
  mutations: {
    UPDATE_COMMUNICATIONS(state, val) {
      state.communications = val
    },
    UPDATE_CARES(state, val) {
      state.cares = val
    },
    UPDATE_IS_DEFAULT(state, val) {
      state.isDefault = val
    },
    UPDATE_CARD_IS_NEW_ITEM_TAB(state, val) {
      state.cardIsNewItemTab = val
    },
    UPDATE_INDICATORS_IS_NEW_ITEM_TAB(state, val) {
      state.indicatorsIsNewItemTab = val
    },
  },
  actions: {
    save(ctx, payload) {
      const notificationConfig = {
        worker_notification_info: payload,
      }

      return new Promise((resolve, reject) => {
        axios
          .post('worker/notification/configuration/set', notificationConfig)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    list() {
      return new Promise((resolve, reject) => {
        axios
          .get('worker/notification/configuration/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
