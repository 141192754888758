function initialState() {
  return {
    status: [],
    startDate: '',
    endDate: '',
    clinicsIds: [],
    healthInsuranceIds: [],
    sourceRequest: [],
    activeRageDays: '',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    status: state => state.status,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
    clinicsIds: state => state.clinicsIds,
    healthInsuranceIds: state => state.healthInsuranceIds,
    sourceRequest: state => state.sourceRequest,
  },
  mutations: {
    UPDATE_STATUS(state, val) {
      state.status = val
    },
    UPDATE_START_DATE(state, val) {
      state.startDate = val
    },
    UPDATE_END_DATE(state, val) {
      state.endDate = val
    },
    UPDATE_CLINICS_IDS(state, val) {
      state.clinicsIds = val
    },
    UPDATE_HEALTH_INSURANCE_IDS(state, val) {
      state.healthInsuranceIds = val
    },
    UPDATE_SOURCE_REQUEST(state, val) {
      state.sourceRequest = val
    },
    UPDATE_ACTIVE_RANGE_DATE(state, val) {
      state.activeRageDays = val
    },
    RESET_STATE(state) {
      Object.assign(state, initialState())
    },
  },
}
