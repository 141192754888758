import axios from '@axios'

function initialState() {
  return {
    bariatric: false,
    pcx_csat: false,
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    bariatric: state => state.bariatric,
    pcxCsat: state => state.pcx_csat,
  },
  mutations: {
    UPDATE_BARIATRIC_SURVEY(state, val) {
      state.bariatric = val
    },
    UPDATE_PCX_CSAT(state, val) {
      state.pcx_csat = val
    },
    RESET(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
  },
  actions: {
    show(ctx, payload) {
      const identifier = {
        survey_identifier: payload.identifier,
        show_unsubscribed: payload.showUnsubscribed ?? false,
        idle_days: payload.maxAnsweredDays,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('worker/survey/link', identifier)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    answer(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('worker/survey/answer', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
  },
}
