import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueMask from 'v-mask'
import VueCompositionAPI from '@vue/composition-api'

import Zendesk from '@dansmaculotte/vue-zendesk'
import VueGtag from 'vue-gtag'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Vue Mask
Vue.use(VueMask)

// Composition API
Vue.use(VueCompositionAPI)

// Zendesk
Vue.use(Zendesk, {
  key: 'd1d46336-ea84-4177-9384-3e6c268e7d85',
  disabled: false,
  hideOnLoad: true,
  settings: {
    webWidget: {
      launcher: {
        label: {
          '*': 'Suporte CUIDO',
        },
      },
      zIndex: 999999,
    },
  },
})

// Analytics
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
  },
}, router)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
