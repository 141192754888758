import axios from '@axios'

function initialState() {
  return {
    action: '',
    link: '',
    title: '',
    showTime: false,
    time: '',
    showFrequency: false,
    frequency: 'Todos os dias',
    frequencyWeek: [],
    frequencyWeekColor: [
      'outline-primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
    ],
    frequencyWeekFormated: '',
    frequencyDate: '',
    idFavorite: '',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    state: state => state,
    action: state => state.action,
    title: state => state.title,
    link: state => state.link,
    showTime: state => state.showTime,
    time: state => state.time,
    date: state => state.date,
    showFrequency: state => state.showFrequency,
    frequency: state => state.frequency,
    frequencyWeek: state => state.frequencyWeek,
    frequencyWeekFormated: state => state.frequencyWeekFormated,
    frequencyWeekColor: state => state.frequencyWeekColor,
    frequencyDate: state => state.frequencyDate,
  },
  mutations: {
    UPDATE_LINK(state, val) {
      state.link = val
    },
    UPDATE_ACTION(state, val) {
      state.action = val
    },
    UPDATE_TITLE(state, val) {
      state.title = val
    },
    UPDATE_SHOW_TIME(state, val) {
      state.showTime = val
    },
    UPDATE_TIME(state, val) {
      state.time = val
    },
    UPDATE_SHOW_FREQUENCY(state, val) {
      state.showFrequency = val
    },
    UPDATE_FREQUENCY(state, val) {
      state.frequency = val
    },
    UPDATE_FREQUENCY_WEEK(state, val) {
      state.frequencyWeek = val
    },
    UPDATE_FREQUENCY_WEEK_COLOR(state, val) {
      state.frequencyWeekColor = val
    },
    UPDATE_FREQUENCY_WEEK_FORMATED(state, val) {
      state.frequencyWeekFormated = val
    },
    UPDATE_FREQUENCY_DATE(state, val) {
      state.frequencyDate = val
    },
    RESET(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
  },
  actions: {
    save() {
      const { formOther } = this.state
      const { patient } = this.state.patient

      const arrayWeek = []
      for (let i = 0; i < formOther.frequencyWeek.length; i += 1) {
        switch (formOther.frequencyWeek[i]) {
          case 'dom':
            arrayWeek.push('sunday')
            break
          case 'seg':
            arrayWeek.push('monday')
            break
          case 'ter':
            arrayWeek.push('tuesday')
            break
          case 'qua':
            arrayWeek.push('wednesday')
            break
          case 'qui':
            arrayWeek.push('thursday')
            break
          case 'sex':
            arrayWeek.push('friday')
            break
          case 'sab':
            arrayWeek.push('saturday')
            break
          default:
        }
      }

      let type = ''
      if (formOther.frequency === 'Todos os dias') {
        type = 'RECURRENT'
      } else if (formOther.frequency === 'Um dia específico') {
        type = 'DATE'
      } else if (formOther.frequency === 'Dias específicos da semana') {
        type = 'CUSTOM'
      } else {
        type = null
      }

      let formData = {}
      formData = {
        member_id: patient.id,
        name: formOther.title,
        time: formOther.time,
        type,
        date: formOther.date,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('member/reminder/create', { member_reminder_info: formData, member_reminder_week_days_info: arrayWeek })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
