export const bariatricPartners = [
  {
    title: 'Johnson e Johnson Medtech',
    image: require('@/assets/images/partners/johnson-e-johnson-medtech.svg'),
  },
  {
    title: 'Ethicon',
    image: require('@/assets/images/partners/ethicon.png'),
  },
]
