export default {
  namespaced: true,
  state: {
    showHelp: false,
  },
  getters: {
    getShowHelp(state) {
      return state.showHelp
    },
  },
  mutations: {
    UPDATE_SHOW_HELP(state, val) {
      state.showHelp = val
    },
  },
  actions: {},
}
