import axios from '@axios'
import moment from 'moment'

function initialState() {
  return {
    bariatricMember: {},
    bariatric: {},
    bariatricJourneyId: '',
    actionTab: 'journey',
    detailTab: 'specialist',
    bariatricWeightStart: moment().add(1, 'days').subtract(1, 'month').format('DD/MM/YYYY'),
    bariatricWeightEnd: moment().format('DD/MM/YYYY'),
    bariatricSurgery: {
      surgery_date: null,
      surgery_days_past: null,
      surgery_weight_loss: null,
    },
    selectedWeight: '',
    bariatricCardListChange: '',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    bariatricMember: state => state.bariatricMember,
    bariatric: state => state.bariatric,
    bariatricId: state => state.bariatric.id,
    journeyProgress: state => state.bariatricMember.journey_progress,
    bariatricJourneyId: state => state.bariatricJourneyId,
    actionTab: state => state.actionTab,
    firstAppointmentDate: state => state.bariatric.first_appointment_date,
    detailTab: state => state.detailTab,
    bariatricWeightStart: state => state.bariatricWeightStart,
    bariatricWeightEnd: state => state.bariatricWeightEnd,
    bariatricSurgery: state => state.bariatricSurgery,
    selectedWeight: state => state.selectedWeight,
    bariatricCardListChange: state => state.bariatricCardListChange,
  },
  mutations: {
    UPDATE_BARIATRIC_MEMBER(state, val) {
      state.bariatricMember = val
    },
    UPDATE_BARIATRIC(state, val) {
      state.bariatric = val
    },
    UPDATE_BARIATRIC_SUGERY(state, val) {
      state.bariatricSurgery = val
    },
    UPDATE_BARIATRIC_STATUS(state, val) {
      state.bariatricMember.bariatric_journey_status = val
    },
    UPDATE_BARIATRIC_JOURNEY_ID(state, val) {
      state.bariatricJourneyId = val
    },
    UPDATE_JOURNEY_PROGRESS(state, val) {
      state.bariatricMember.journey_progress[val.key] = val.value
    },
    UPDATE_ACTION_TAB(state, val) {
      state.actionTab = val
    },
    UPDATE_DETAIL_TAB(state, val) {
      state.detailTab = val
    },
    UPDATE_SOURCE_REQUEST(state, val) {
      state.bariatric.source_request = val
    },
    UPDATE_BARIATRIC_WEIGHT_START(state, val) {
      state.bariatricWeightStart = val
    },
    UPDATE_BARIATRIC_WEIGHT_END(state, val) {
      state.bariatricWeightEnd = val
    },
    UPDATE_SELECTED_WEIGHT(state, val) {
      state.selectedWeight = val
    },
    RESET(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    UPDATE_BARIATRIC_CARD_LIST_CHANGE(state, val) {
      state.bariatricCardListChange = val
    },
  },
  actions: {
    ufList() {
      return new Promise((resolve, reject) => {
        axios
          .get('/state/enumerate')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    citySearch(ctx, payload) {
      const filter = {
        filters: {
          state_code: payload,
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/city/search', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    referralList() {
      return new Promise((resolve, reject) => {
        axios
          .get('/referral/source/list')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    insuranceSearch(ctx, payload) {
      const filter = {
        filters: {
          name: payload,
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/health/insurance/plan/search', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    fetchBariatrics(ctx, payload) {
      const filter = {
        filters: {
          notification_type: payload.type,
          member_name: payload.q,
          status: payload.status,
          start_date: payload.dateStart,
          end_date: payload.dateEnd,
          clinic_ids: payload.clinics,
          health_insurance_ids: payload.healthInsurance,
          source_requests: payload.source_requests,
        },
        limit: payload.perPage,
        page: payload.page,
        sort_by: payload.sortBy,
        sort_direction: payload?.sortDirection.toUpperCase(),
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/member/search', filter)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    report(ctx, payload) {
      const filter = {
        filters: {
          source_requests: payload.sourceRequests,
          health_insurance_plan_ids: payload.planIds,
        },
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/report', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listProtocols() {
      return new Promise((resolve, reject) => {
        axios
          .post('bariatric/protocol/model/list')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    saveBariatric(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/update', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    showBariatric(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/member/show', { bariatric_journey_id: data })
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    showJourneyProgress(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/progress/show', { bariatric_journey_id: data })
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    saveBariatricStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/status/update', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    saveSurgeryRequest(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/surgery/request/add', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    showSurgeryRequest(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/surgery/request/show', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    surgeryTypeList() {
      return new Promise((resolve, reject) => {
        axios
          .get('/surgery/type/enumerate')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    saveSurgery(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/surgery/add', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    showSurgery(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/surgery/show', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    saveSpecialist(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/health/specialist/update', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    showSpecialist(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/health/specialist/show', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    saveExam(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/exam/update', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    deleteSpecialist(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/health/specialist/delete', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    showExam(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/exam/show', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    saveComment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/comment/create', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    deleteExam(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/exam/delete', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    updateComment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/comment/update', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    showComment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/comment/list', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    removeComment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/comment/delete', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    getSurgeriesReport() {
      return new Promise((resolve, reject) => {
        axios
          .get('/bariatric/journey/report/surgeries')
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    saveContact(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/worker/update', data)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    showWorker() {
      return new Promise((resolve, reject) => {
        axios
          .get('/worker/show')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    searchBariatricWeightMonitor(ctx, payload) {
      const filter = {
        filters: {
          bariatric_journey_id: this.getters['bariatric/bariatricId'],
          start_date: moment(payload.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          end_date: moment(payload.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/weight/monitor/search', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    fetchBariatricCards(ctx, payload) {
      const filter = {
        filters: {
          member_name: payload.q,
        },
        limit: payload.perPage,
        page: payload.page,
        sort_by: payload.sortBy,
        sort_direction: payload?.sortDirection.toUpperCase(),
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/card/search', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    updateBariatricCardsStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/card/status/set', payload)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    showBariatricCard(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/card/show', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBariatricCard(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/card/update', payload)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    healthInsurancePlans() {
      const filter = {
        filters: {
          name: '',
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/worker/health/insurance/search', filter)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },

    listCares(ctx, memberId) {
      const filter = {
        filters: {
          member_id: memberId,
          language: 'pt_BR',
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('member/care/list', filter)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    searchCares(ctx, name) {
      const filter = {
        filters: {
          name,
          language: 'pt_BR',
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('member/care/search', filter)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    updateCares(ctx, payload) {
      const payloadCare = {
        care_ids: payload.cares,
        member_id: payload.memberId,
        language: 'pt_BR',
      }
      return new Promise((resolve, reject) => {
        axios
          .post('member/care/update', payloadCare)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },

    searchClinics(ctx, name) {
      const filter = {
        filters: {
          name,
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/bariatric/journey/clinic/search', filter)
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },

    bariatricClinics() {
      return new Promise((resolve, reject) => {
        axios
          .get('/bariatric/journey/clinic/list')
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
  },
}
