import axios from '@axios'

function initialState() {
  return {
    program: {},
    idProgram: '',
    nameProgram: '',
    agreedProgram: true,
    medicationSelected: [],
    stockRechargeMedicationSelected: [],
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    program: state => state.program,
    idProgram: state => state.program.id,
    nameProgram: state => state.program.name,
    logoProgram: state => state.program.logo_file_path,
    agreedProgram: state => state.program.agreed,
    identifierProgram: state => state.program.identifier,
    medicationSelected: state => state.medicationSelected,
    pagesInfoProgram: state => state.program.pages_info,
    rechargeStock: state => state.program.stock_recharge,
  },
  mutations: {
    UPDATE_PROGRAM(state, val) {
      state.program = val
    },
    UPDATE_MEDICATION_SELECTED(state, val) {
      state.medicationSelected = val
    },
    UPDATE_AGREED_PROGRAM(state, val) {
      state.program.agreed = val
    },
    RESET_MEDICATION_SELECTED(state) {
      state.medicationSelected = []
    },
    UPDATE_STOCK_RECHARGE_MEDICATION_SELECTED(state, val) {
      state.stockRechargeMedicationSelected = val
    },
  },
  actions: {
    listProgram() {
      return new Promise((resolve, reject) => {
        axios
          .get('firstbox/program/list')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listMedication(ctx, payload) {
      const filter = {
        program_id: this.getters['stock/idProgram'],
        medication_name: payload.q,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('firstbox/stock/list', { filters: filter })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    getAgreement() {
      const program = this.getters['stock/identifierProgram']
      return new Promise((resolve, reject) => {
        axios
          .get(`firstbox/agreement/${program}`)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    save(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('firstbox/token/create', { code_info: data.codeInfo.code_info, stock_ids: data.stockIds.stock_ids, eans_medications: data.eansMedications.eans_medications })
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },
    saveAgree(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('firstbox/agreement/agree', { agreement_info: data.agreementInfo.agreement_info, client_info: data.agreementInfo.client_info })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listHistory() {
      const filter = {
        program_id: this.getters['stock/idProgram'],
      }
      return new Promise((resolve, reject) => {
        axios
          .post('firstbox/stock/history/list', { filters: filter })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    deleteToken(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('firstbox/token/delete', { token_id: data.token_id })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    stockRecharge(ctx) {
      const workerStockIds = ctx.state.stockRechargeMedicationSelected.map(item => item.id)
      return new Promise((resolve, reject) => {
        axios
          .post('firstbox/stock/recharge', { program_id: ctx.state.program.id, worker_stock_ids: workerStockIds })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
