function initialState() {
  return {
    locale: 'pt_BR',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    locale: state => state.locale,
  },
  mutations: {
    UPDATE_LOCALE(state, val) {
      state.locale = val
    },
  },
  actions: {},
}
