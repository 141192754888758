import axios from '@axios'
import moment from 'moment'

function initialState() {
  return {
    patient: {},
    medication: '',
    adherenceDateStart: '',
    adherenceDateEnd: '',
    careDateStart: moment().add(1, 'days').subtract(1, 'month').format('DD/MM/YYYY'),
    careDateEnd: moment().format('DD/MM/YYYY'),
    showTab: true,
    showReminderTable: true,
    showReminderAdd: false,
    showReminderFavorite: false,
    showOtherReminderTable: true,
    showContentTable: true,
    showContentAdd: false,
    favoriteForm: false,
    active: 'Adesão',
    showNotification: false,
    notificationValue: {},
    selectedWeight: '',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    patient: state => state.patient,
    patientId: state => state.patient.id,
    medication: state => state.medication,
    adherenceDateStart: state => state.adherenceDateStart,
    adherenceDateEnd: state => state.adherenceDateEnd,
    careDateStart: state => state.careDateStart,
    careDateEnd: state => state.careDateEnd,
    showTab: state => state.showTab,
    showReminderTable: state => state.showReminderTable,
    showReminderAdd: state => state.showReminderAdd,
    showReminderFavorite: state => state.showReminderFavorite,
    showOtherReminderTable: state => state.showOtherReminderTable,
    showContentTable: state => state.showContentTable,
    showContentAdd: state => state.showContentAdd,
    favoriteForm: state => state.favoriteForm,
    showActive: state => state.active,
    showNotification: state => state.showNotification,
    valueNotification: state => state.notificationValue,
    selectedWeight: state => state.selectedWeight,
  },
  mutations: {
    UPDATE_PATIENT(state, val) {
      state.patient = val
    },
    UPDATE_MEDICATION(state, val) {
      state.medication = val
    },
    UPDATE_ADHERENCE_DATE_START(state, val) {
      state.adherenceDateStart = val
    },
    UPDATE_ADHERENCE_DATE_END(state, val) {
      state.adherenceDateEnd = val
    },
    UPDATE_CARE_DATE_START(state, val) {
      state.careDateStart = val
    },
    UPDATE_CARE_DATE_END(state, val) {
      state.careDateEnd = val
    },
    UPDATE_SHOW_TAB(state, val) {
      state.showTab = val
    },
    UPDATE_SHOW_REMINDER_TABLE(state, val) {
      state.showReminderTable = val
    },
    UPDATE_SHOW_REMINDER_ADD(state, val) {
      state.showReminderAdd = val
    },
    UPDATE_SHOW_REMINDER_FAVORITE(state, val) {
      state.showReminderFavorite = val
    },
    UPDATE_SHOW_OTHER_REMINDER_TABLE(state, val) {
      state.showOtherReminderTable = val
    },
    UPDATE_SHOW_CONTENT_TABLE(state, val) {
      state.showContentTable = val
    },
    UPDATE_SHOW_CONTENT_ADD(state, val) {
      state.showContentAdd = val
    },
    UPDATE_FAVORITE_FORM(state, val) {
      state.favoriteForm = val
    },
    UPDATE_ACTIVE(state, val) {
      state.active = val
    },
    UPDATE_SHOW_NOTIFICATION(state, val) {
      state.showNotification = val
    },
    UPDATE_NOTIFICATION(state, val) {
      state.notificationValue = val
    },
    UPDATE_SELECTED_WEIGHT(state, val) {
      state.selectedWeight = val
    },
    RESET(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
  },
  actions: {
    countMembers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/members/count')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    fetchPatients(ctx, payload) {
      let status = ''
      if (payload.status === false) {
        status = ''
      } else {
        status = payload.status
      }

      const filter = {
        filters: {
          member_name: payload.q,
          member_status: status,
          care_ids: payload.care,
        },
        limit: payload.perPage,
        page: payload.page,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/list', filter)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listReminder(ctx, payload) {
      const filter = {
        member_id: this.getters['patient/patientId'],
        limit: payload.perPage,
        page: payload.page,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/medication/list', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    removeReminder(ctx, payload) {
      const arrayId = []
      payload.data.forEach(element => {
        arrayId.push(element.id)
      })

      const filter = {
        member_id: this.getters['patient/patientId'],
        member_medication_ids: arrayId,
      }

      return new Promise((resolve, reject) => {
        axios
          .post('member/medication/destroy', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listOtherReminder(ctx, payload) {
      const filter = {
        filters: {
          member_id: this.getters['patient/patientId'],
        },
        limit: payload.perPage,
        page: payload.page,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/reminder/list', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    removeOtherReminder(ctx, payload) {
      const arrayId = []
      payload.data.forEach(element => {
        arrayId.push(element.member_reminder_info.id)
      })
      const filter = {
        member_reminder_ids: arrayId,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/reminder/delete', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listContent(ctx, payload) {
      const filter = {
        member_id: this.getters['patient/patientId'],
        limit: payload.perPage,
        page: payload.page,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/content/list', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    removeContent(ctx, payload) {
      const arrayId = []
      payload.data.forEach(element => {
        arrayId.push(element.id)
      })
      const filter = {
        member_library_content_ids: arrayId,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('member/content/destroy', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listMedication(ctx, payload) {
      const filter = {
        filters: {
          member_id: this.getters['patient/patientId'],
          medication_ids: [],
          start_date: payload.start_date,
          end_date: payload.end_date,
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('member/medication/adherence/list', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    dataChartsAdherence(ctx, payload) {
      const medicationId = []
      payload.medication.forEach(element => {
        medicationId.push(element.medication_id)
      })
      const filter = {
        filters: {
          member_id: this.getters['patient/patientId'],
          medication_ids: medicationId,
          start_date: moment(this.getters['patient/adherenceDateStart'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          end_date: moment(this.getters['patient/adherenceDateEnd'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/medication/adherence/report', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    dataChartsCare() {
      const filter = {
        member_id: this.getters['patient/patientId'],
        filters: {
          start_date: moment(this.getters['patient/careDateStart'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          end_date: moment(this.getters['patient/careDateEnd'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/seizure/history/search', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    fetchPhq9() {
      const filter = {
        member_id: this.getters['patient/patientId'],
        filters: {
          start_date: moment(this.getters['patient/careDateStart'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          end_date: moment(this.getters['patient/careDateEnd'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/survey/phq9/history/search', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listCare() {
      let filter = ''
      if (this.getters['patient/patientId'] === '') {
        filter = {
          filters: {
            language: 'pt_BR',
          },
        }
      } else {
        filter = {
          filters: {
            member_id: this.getters['patient/patientId'],
            language: 'pt_BR',
          },
        }
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/care/list', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listJoyrneys() {
      const filter = {
        member_id: this.getters['patient/patientId'],
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/journey/list', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    dataChartsCareHeartFailure(ctx, payload) {
      const filter = {
        member_id: this.getters['patient/patientId'],
        filters: {
          monitor_type: payload.monitor_type,
          start_date: moment(this.getters['patient/careDateStart'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
          end_date: moment(this.getters['patient/careDateEnd'], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/health/monitor/search', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    saveWeightNotification(ctx, payload) {
      const formData = {
        health_notification_info: {
          member_id: this.getters['patient/patientId'],
          ideal_weight: payload.weight,
          weight_threshold: payload.weightThreshold,
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/health/notification/add', formData)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    listNotifications() {
      const filter = {
        filters: {
          member_id: this.getters['patient/patientId'],
        },
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/health/notification/search', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    removeNotification(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/member/health/notification/destroy', { health_notification_id: payload })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    readNotification() {
      const filter = {
        member_id: this.getters['patient/patientId'],
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/member/health/notification/read', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
