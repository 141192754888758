import axios from '@axios'

function initialState() {
  return {
    role: '',
    name: '',
    email: '',
    onboarding: false,
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    role: state => state.role,
    onboarding: state => state.onboarding,
  },
  mutations: {
    UPDATE_ROLE(state, val) {
      state.role = val
    },
    UPDATE_NAME(state, val) {
      state.name = val
    },
    UPDATE_EMAIL(state, val) {
      state.email = val
    },
    UPDATE_ONBOARDING(state, val) {
      state.onboarding = val
    },
  },
  actions: {
    getAuthCode() {
      return new Promise((resolve, reject) => {
        axios
          .post('authcode/pcxpro')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
