import axios from '@axios'
import moment from 'moment'

function initialState() {
  return {
    selectMedication: [],
    showTime: false,
    time: 'Não selecionar um horário agora',
    timeInterval: [],
    timeTime: [],
    timeAmount: [],
    timeTimeFree: [],
    timeAmountFree: [],
    timeTimeFixed: [],
    timeAmountFixed: [],
    showFrequency: false,
    frequency: 'Não selecionar uma frequência agora',
    frequencyWeek: [],
    frequencyWeekColor: [
      'outline-primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
    ],
    frequencyWeekFormated: '',
    frequencyInterval: [],
    frequencyIntervalColor: [
      'primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
      'outline-primary',
    ],
    showDuration: false,
    duration: 'Não selecionar uma duração agora',
    durationDateInitial: '',
    durationDateEnd: '',
    action: 'Adicionar novo medicamento favorito',
    idFavorite: '',
    idSelect: '',
    link: '',
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    medication: state => state.selectMedication,
    time: state => state.time,
    timeInterval: state => state.timeInterval,
    timeTime: state => state.timeTime,
    timeAmount: state => state.timeAmount,
    timeTimeFree: state => state.timeTimeFree,
    timeAmountFree: state => state.timeAmountFree,
    timeTimeFixed: state => state.timeTimeFixed,
    timeAmountFixed: state => state.timeAmountFixed,
    frequency: state => state.frequency,
    frequencyInternal: state => state.frequencyInterval,
    frequencyInternalColor: state => state.frequencyIntervalColor,
    frequencyWeek: state => state.frequencyWeek,
    frequencyWeekFormated: state => state.frequencyWeekFormated,
    frequencyWeekColor: state => state.frequencyWeekColor,
    duration: state => state.duration,
    durationDateInitial: state => state.durationDateInitial,
    durationDateEnd: state => state.durationDateEnd,
    state: state => state,
    action: state => state.action,
    idFavorite: state => state.idFavorite,
    idSelect: state => state.idSelect,
    link: state => state.link,
  },
  mutations: {
    UPDATE_MEDICATION(state, val) {
      state.selectMedication = val
    },
    UPDATE_SHOW_TIME(state, val) {
      state.showTime = val
    },
    UPDATE_TIME(state, val) {
      state.time = val
    },
    UPDATE_TIME_INTERVAL(state, val) {
      state.timeInterval = val
    },
    UPDATE_TIME_TIME(state, val) {
      state.timeTime = val
    },
    UPDATE_TIME_AMOUNT(state, val) {
      state.timeAmount = val
    },
    UPDATE_TIME_TIME_FIXED(state, val) {
      state.timeTimeFixed = val
    },
    UPDATE_TIME_AMOUNT_FIXED(state, val) {
      state.timeAmountFixed = val
    },
    UPDATE_TIME_TIME_FREE(state, val) {
      state.timeTimeFree = val
    },
    UPDATE_TIME_AMOUNT_FREE(state, val) {
      state.timeAmountFree = val
    },
    UPDATE_SHOW_FREQUENCY(state, val) {
      state.showFrequency = val
    },
    UPDATE_FREQUENCY(state, val) {
      state.frequency = val
    },
    UPDATE_FREQUENCY_WEEK(state, val) {
      state.frequencyWeek = val
    },
    UPDATE_FREQUENCY_WEEK_COLOR(state, val) {
      state.frequencyWeekColor = val
    },
    UPDATE_FREQUENCY_WEEK_FORMATED(state, val) {
      state.frequencyWeekFormated = val
    },
    UPDATE_FREQUENCY_INTERVAL(state, val) {
      state.frequencyInterval = val
    },
    UPDATE_FREQUENCY_INTERVAL_COLOR(state, val) {
      state.frequencyIntervalColor = val
    },
    UPDATE_SHOW_DURATION(state, val) {
      state.showDuration = val
    },
    UPDATE_DURATION(state, val) {
      state.duration = val
    },
    UPDATE_DURATION_DATE_INITIAL(state, val) {
      state.durationDateInitial = val
    },
    UPDATE_DURATION_DATE_END(state, val) {
      state.durationDateEnd = val
    },
    UPDATE_ACTION(state, val) {
      state.action = val
    },
    UPDATE_ID_FAVORITE(state, val) {
      state.idFavorite = val
    },
    UPDATE_ID_SELECT(state, val) {
      state.idSelect = val
    },
    UPDATE_LINK(state, val) {
      state.link = val
    },
    RESET(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
  },
  actions: {
    findMedication(ctx, findMedication) {
      const filter = {
        medication: findMedication,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/medication/search', { filters: filter })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    findEanMedication(ctx, findEanMedication) {
      const filter = {
        ean: findEanMedication,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/medication/search', { filters: filter })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    saveFavorite() {
      const { formMedication } = this.state

      const scheduleTimes = []
      const lengthTime = formMedication.timeTime.length

      for (let i = 0; i < lengthTime; i += 1) {
        scheduleTimes.push(({ time: formMedication.timeTime[i], dose: formMedication.timeAmount[i] }))
      }

      let medication = {}
      medication = {
        ean: formMedication.selectMedication.ean1.toString(),
      }

      let time = {}
      if (formMedication.time === 'Horários livres') {
        time = {
          is_fixed_time: false,
          schedule_times: scheduleTimes,
        }
      } else if (formMedication.time === 'Intervalos fixos (8 em 8h, 6 em 6h…)') {
        time = {
          is_fixed_time: true,
          schedule_times: scheduleTimes,
        }
      } else {
        time = {}
      }

      const arrayWeek = []
      for (let i = 0; i < formMedication.frequencyWeek.length; i += 1) {
        switch (formMedication.frequencyWeek[i]) {
          case 'dom':
            arrayWeek.push('sunday')
            break
          case 'seg':
            arrayWeek.push('monday')
            break
          case 'ter':
            arrayWeek.push('tuesday')
            break
          case 'qua':
            arrayWeek.push('wednesday')
            break
          case 'qui':
            arrayWeek.push('thursday')
            break
          case 'sex':
            arrayWeek.push('friday')
            break
          case 'sab':
            arrayWeek.push('saturday')
            break
          default:
        }
      }

      let frequency = {}
      if (formMedication.frequency === 'Dias contínuos (sem pausas)') {
        frequency = {
          type: 'RECURRENT',
        }
      } else if (formMedication.frequency === 'Dias alternados (4 em 4 dias, 7 em 7…)') {
        frequency = {
          type: 'ALTERNATED',
          days_interval: formMedication.frequencyInterval,
        }
      } else if (formMedication.frequency === 'Dias específicos da semana') {
        frequency = {
          type: 'CUSTOM',
          schedule_week_days: arrayWeek,
        }
      } else {
        frequency = {
          type: 'EMPTY',
        }
      }

      let duration = {}
      if (formMedication.duration === 'Uso contínuo (sem data de término)') {
        duration = {
          duration_type: 'CONTINUOUS',
        }
      } else if (formMedication.duration === 'Quantidade de dias (início e fim definidos)') {
        duration = {
          duration_type: 'INTERVAL',
        }
      } else {
        duration = {
          duration_type: 'EMPTY',
        }
      }

      let dataForm = {}

      if (this.state.formMedication.link === 'favorite') {
        if (this.state.formMedication.action === 'Adicionar novo medicamento favorito') {
          dataForm = Object.assign(medication, time, frequency, duration)
          return new Promise((resolve, reject) => {
            axios
              .post('template/schedule/create', { template_schedule_info: dataForm })
              .then(response => { resolve(response) })
              .catch(error => reject(error))
          })
        }
        const id = formMedication.idFavorite
        dataForm = Object.assign(medication, time, frequency, duration)
        return new Promise((resolve, reject) => {
          axios
            .post('template/schedule/update', { template_schedule_id: id, template_schedule_info: dataForm })
            .then(response => { resolve(response) })
            .catch(error => reject(error))
        })
      }
      if (this.state.formMedication.action === 'Adicionar um novo lembrete de medicamento') {
        dataForm = Object.assign(medication, time, frequency, duration)
        return new Promise((resolve, reject) => {
          axios
            .post('template/schedule/create', { template_schedule_info: dataForm })
            .then(response => { resolve(response) })
            .catch(error => reject(error))
        })
      }
      const id = formMedication.idFavorite
      dataForm = Object.assign(medication, time, frequency, duration)
      return new Promise((resolve, reject) => {
        axios
          .post('template/schedule/update', { template_schedule_id: id, template_schedule_info: dataForm })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    saveReminder() {
      const { formMedication } = this.state
      const { patient } = this.state.patient

      const scheduleTimes = []
      const lengthTime = formMedication.timeTime.length

      for (let i = 0; i < lengthTime; i += 1) {
        scheduleTimes.push(({ time: formMedication.timeTime[i], dose: formMedication.timeAmount[i] }))
      }

      let medication = {}

      medication = {
        member_id: patient.id,
        ean: formMedication.selectMedication.ean1.toString(),
      }

      let time = {}
      if (formMedication.time === 'Horários livres') {
        time = {
          is_fixed_time: false,
          schedule_times_info: scheduleTimes,
        }
      } else if (formMedication.time === 'Intervalos fixos (8 em 8h, 6 em 6h…)') {
        time = {
          is_fixed_time: true,
          schedule_times_info: scheduleTimes,
        }
      } else {
        time = {}
      }

      const arrayWeek = []
      for (let i = 0; i < formMedication.frequencyWeek.length; i += 1) {
        switch (formMedication.frequencyWeek[i]) {
          case 'dom':
            arrayWeek.push('sunday')
            break
          case 'seg':
            arrayWeek.push('monday')
            break
          case 'ter':
            arrayWeek.push('tuesday')
            break
          case 'qua':
            arrayWeek.push('wednesday')
            break
          case 'qui':
            arrayWeek.push('thursday')
            break
          case 'sex':
            arrayWeek.push('friday')
            break
          case 'sab':
            arrayWeek.push('saturday')
            break
          default:
        }
      }

      let frequency = {}
      if (formMedication.frequency === 'Dias contínuos (sem pausas)') {
        frequency = {
          type: 'RECURRENT',
        }
      } else if (formMedication.frequency === 'Dias alternados (4 em 4 dias, 7 em 7…)') {
        frequency = {
          type: 'ALTERNATED',
          days_interval: formMedication.frequencyInterval,
        }
      } else if (formMedication.frequency === 'Dias específicos da semana') {
        frequency = {
          type: 'CUSTOM',
          schedule_week_days_info: arrayWeek,
        }
      } else {
        frequency = {
          type: 'EMPTY',
        }
      }

      let duration = {}
      if (formMedication.duration === 'Uso contínuo (sem data de término)') {
        duration = {
          duration_type: 'CONTINUOUS',
          start_date: moment().format('YYYY-MM-DD'),
        }
      } else if (formMedication.duration === 'Quantidade de dias (início e fim definidos)') {
        duration = {
          duration_type: 'INTERVAL',
          start_date: formMedication.durationDateInitial,
          end_date: formMedication.durationDateEnd,
        }
      } else {
        duration = {
          duration_type: 'NOSELETED',
        }
      }

      let dataForm = {}

      // if (this.state.formMedication.action === 'Adicionar um novo lembrete de medicamento') {
      dataForm = Object.assign(time, frequency, duration)
      return new Promise((resolve, reject) => {
        axios
          .post('member/medication/create', { member_medication_info: medication, schedule_info: dataForm })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
      // }
      // const id = formMedication.idFavorite
      // dataForm = Object.assign(medication, time, frequency, duration)
      // return new Promise((resolve, reject) => {
      //   axios
      //     .post('template/schedule/update', { template_schedule_id: id, template_schedule_info: dataForm })
      //     .then(response => { resolve(response) })
      //     .catch(error => reject(error))
      // })
    },

    edit(ctx, payload) {
      const filter = {
        filters: {
          template_schedule_id: payload.data[0],
        },
        limit: 10,
        page: 1,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('template/schedule/list', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
