import axios from '@axios'

function initialState() {
  return {
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {},
  mutations: {},
  actions: {
    list(ctx, payload) {
      const filter = {
        filters: {
          medication_name: payload.q,
        },
        limit: payload.perPage,
        page: payload.page,
      }
      return new Promise((resolve, reject) => {
        axios
          .post('template/schedule/list', filter)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    remove(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post('template/schedule/delete', { template_schedule_ids: data })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
