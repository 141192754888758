import axios from '@axios'

export default {
  namespaced: true,

  actions: {
    list() {
      return new Promise((resolve, reject) => {
        axios
          .get('worker/notification/list')
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    read(state, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('worker/notification/read', { notification_id: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
